import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import {
  createPickerSession,
  pollSession,
  fetchSelectedMediaItems,
} from "./googlePhotosPicker";
import "../styles/PhotoSelector.css";

const PHOTO_LIMIT = process.env.REACT_APP_NEXT_PUBLIC_PHOTO_LIMIT || 100;

const PhotoSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [pickerUri, setPickerUri] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [status, setStatus] = useState("initializing");
  const [pickerMode, setPickerMode] = useState("web");
  const [hasStartedSelection, setHasStartedSelection] = useState(false);
  const [validPhotoCount, setValidPhotoCount] = useState(0);
  const [selectionError, setSelectionError] = useState(null);
  const [exceededLimit, setExceededLimit] = useState(false);
  // Add to PhotoSelector.js
  const [lastPollingTime, setLastPollingTime] = useState(Date.now());
  const [canManuallyRefresh, setCanManuallyRefresh] = useState(false);
  const checkSelectionRef = React.useRef(null);

  useEffect(() => {
    // Store session state in localStorage
    if (sessionId && hasStartedSelection) {
      localStorage.setItem(
        "photoPickerSession",
        JSON.stringify({
          sessionId,
          startTime: Date.now(),
          lastPoll: Date.now(),
        })
      );
    }
  }, [sessionId, hasStartedSelection]);

  // On component mount, check for existing session
  useEffect(() => {
    const savedSession = localStorage.getItem("photoPickerSession");
    if (savedSession) {
      const { sessionId: savedId, startTime } = JSON.parse(savedSession);
      // If session is less than 30 mins old, restore it
      if (Date.now() - startTime < 30 * 60 * 1000) {
        setSessionId(savedId);
        setHasStartedSelection(true);
        setStatus("waiting");
      } else {
        localStorage.removeItem("photoPickerSession");
      }
    }
  }, []);

  useEffect(() => {
    // If no updates for 30 seconds, allow manual refresh
    const timer = setTimeout(() => {
      setCanManuallyRefresh(true);
    }, 30000);

    return () => clearTimeout(timer);
  }, [lastPollingTime]);

  useEffect(() => {
    // Cleanup function
    return () => {
      // Clear session data on unmount
      localStorage.removeItem("photoPickerSession");
      // Clear any pending timers
      setCanManuallyRefresh(false);
    };
  }, []);

  // Initialize picker session
  useEffect(() => {
    const initSession = async () => {
      try {
        const { sessionId, pickerUri } = await createPickerSession();
        setSessionId(sessionId);
        setPickerUri(pickerUri);
        setStatus("selecting");
      } catch (error) {
        console.error("Failed to create session:", error);
        onError?.("Failed to start photo selection. Please try again.");
        onClose();
      }
    };

    initSession();
  }, [onClose, onError]);

  useEffect(() => {
    let timeoutId;

    const checkSelection = async () => {
      // For web picker, maintain existing behavior requiring manual start
      if (pickerMode === "web" && !hasStartedSelection) return;

      // For mobile, we'll check regardless of hasStartedSelection
      if (!sessionId) return;

      try {
        const session = await pollSession(sessionId);
        console.log("Poll session response:", session);

        // Auto-transition for mobile mode only
        if (
          pickerMode === "mobile" &&
          session.state === "SELECTION_IN_PROGRESS" &&
          !hasStartedSelection
        ) {
          console.log(
            "Detected mobile user selection activity, transitioning to waiting screen"
          );
          setHasStartedSelection(true);
          setStatus("waiting");
        }

        if (session.mediaItemsSet) {
          // For mobile mode, ensure we're in the right state if we haven't set it yet
          if (pickerMode === "mobile" && !hasStartedSelection) {
            setHasStartedSelection(true);
            setStatus("waiting");
          }

          // Rest of your existing media processing code remains exactly the same
          const mediaItems = await fetchSelectedMediaItems(sessionId);
          console.log("Raw media items from Picker:", mediaItems);

          if (!Array.isArray(mediaItems) || mediaItems.length === 0) {
            throw new Error("No media items selected or invalid response.");
          }

          if (mediaItems.length > PHOTO_LIMIT) {
            setExceededLimit(true);
            setValidPhotoCount(mediaItems.length);
            setStatus("error");
            setSelectionError(
              `You've selected ${mediaItems.length} photos, which exceeds the limit of ${PHOTO_LIMIT}. Please make a new selection with fewer photos.`
            );
            return;
          }

          // Existing photo filtering and processing code...
          const photoItems = mediaItems.filter((item) => {
            if (item.type !== "PHOTO") {
              console.warn(`Skipping non-photo item with type: ${item.type}`);
              return false;
            }

            if (!item.id || !item.baseUrl || !item.mimeType) {
              console.warn(
                `Skipping item missing required fields:`,
                JSON.stringify(
                  {
                    hasId: !!item.id,
                    hasBaseUrl: !!item.baseUrl,
                    hasMimeType: !!item.mimeType,
                  },
                  null,
                  2
                )
              );
              return false;
            }

            return true;
          });

          if (photoItems.length === 0) {
            setSelectionError(
              "No valid photos found in your selection. Please try again."
            );
            setStatus("error");
            return;
          }

          const transformedPhotos = photoItems.map((item) => ({
            id: item.id,
            baseUrl: item.baseUrl,
            mimeType: item.mimeType,
            filename: item.filename || `photo_${item.id}.jpg`,
            mediaMetadata: {
              creationTime:
                item.mediaMetadata?.creationTime || new Date().toISOString(),
            },
          }));

          if (photoItems.length > 0) {
            setSelectionError(null);
            setValidPhotoCount(photoItems.length);
            setStatus("processing");

            console.log(`Processing ${photoItems.length} photos...`);
            setTimeout(() => {
              onPhotosSelected(transformedPhotos);
              onClose();
            }, 1000);
          }
        } else {
          // Continue polling
          timeoutId = setTimeout(checkSelection, 5000);
        }
      } catch (error) {
        console.error("Error processing selected photos:", error);
        onError?.(
          error.message || "Error processing selected photos. Please try again."
        );
        onClose();
      }
    };

    // Store the function reference
    checkSelectionRef.current = checkSelection;

    // Start checking
    checkSelection();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    sessionId,
    hasStartedSelection,
    pickerMode,
    onClose,
    onError,
    onPhotosSelected,
  ]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("photoPickerSession");
    };
  }, []);

  const handleManualRefresh = async () => {
    if (checkSelectionRef.current) {
      setCanManuallyRefresh(false);
      setLastPollingTime(Date.now());
      try {
        await checkSelectionRef.current();
      } catch (error) {
        console.error("Manual refresh failed:", error);
        // Allow another refresh attempt immediately if it fails
        setCanManuallyRefresh(true);
      }
    }
  };

  const handleRetry = async () => {
    try {
      // Clear pickerUri first to show initializing state
      setPickerUri(null);
      setStatus("initializing");

      // Create a new session
      const { sessionId: newSessionId, pickerUri: newPickerUri } =
        await createPickerSession();

      // Reset all the states
      setHasStartedSelection(false);
      setExceededLimit(false);
      setSelectionError(null);

      // Set the new session info
      setSessionId(newSessionId);
      setPickerUri(newPickerUri);
      setStatus("selecting");
    } catch (error) {
      console.error("Failed to create new session:", error);
      onError?.("Failed to restart photo selection. Please try again.");
      onClose();
    }
  };

  const handleSelectionStart = () => {
    setHasStartedSelection(true);
    setStatus("waiting");
  };

  return !pickerUri ? (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="loading-indicator">
          <i className="fas fa-spinner fa-spin"></i>
          <span>Initializing photo selector...</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="modal-backdrop">
      <div className="modal-content photo-selector">
        <button onClick={onClose} className="close-x">
          ×
        </button>

        {!hasStartedSelection ? (
          <div className="picker-container">
            <div className="photo-limit-info">
              <p>You can select up to {PHOTO_LIMIT} photos at once.</p>
            </div>
            <div className="mode-selector">
              <div
                className={`mode-option ${
                  pickerMode === "web" ? "active" : ""
                }`}
                onClick={() => setPickerMode("web")}
              >
                <div className="mode-label">Web Picker</div>
                <p className="mode-description">Direct Link</p>
              </div>
              <div
                className={`mode-option ${
                  pickerMode === "mobile" ? "active" : ""
                }`}
                onClick={() => setPickerMode("mobile")}
              >
                <div className="mode-label">Mobile Picker</div>
                <p className="mode-description">QR Code</p>
              </div>
            </div>

            <div className="picker-content">
              {pickerMode === "web" ? (
                <div className="web-picker">
                  <h3>Visit this link to pick images in a new tab:</h3>
                  <a
                    href={pickerUri}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleSelectionStart}
                    className="picker-link"
                  >
                    Open Google Photos
                  </a>
                </div>
              ) : (
                <div className="mobile-picker">
                  <h3>Scan this QR code to select your photos</h3>
                  <div className="qr-code-wrapper">
                    <QRCodeSVG value={pickerUri} size={200} level="H" />
                    {/* Remove the button and add a helpful message */}
                  </div>
                </div>
              )}
              <div className="picker-info">
                <p>
                  This page will automatically update when you've completed your
                  selection.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="status-container">
            {status === "processing" ? (
              <div className="processing-status">
                <i className="fas fa-spinner fa-spin"></i>
                <span>Processing {validPhotoCount} selected photos...</span>
              </div>
            ) : status === "error" ? (
              <div className="error-status">
                <i className="fas fa-exclamation-circle"></i>
                <div className="status-message">
                  <strong>{selectionError}</strong>
                  {exceededLimit && (
                    <div className="exceeded-limit-actions">
                      <button onClick={handleRetry} className="retry-button">
                        Make New Selection
                      </button>
                      <p className="limit-note">
                        Please select {PHOTO_LIMIT} photos or fewer.
                      </p>
                    </div>
                  )}
                  {!exceededLimit && <p>You can try selecting photos again.</p>}
                </div>
              </div>
            ) : (
              <div className="waiting-status">
                <i className="fas fa-sync-alt"></i>
                <div className="status-message">
                  <strong>Waiting for your selection...</strong>
                  <p>
                    You can complete your selection in the Google Photos window.
                    This page will update automatically once you're done.
                  </p>
                  {canManuallyRefresh && (
                    <button
                      onClick={handleManualRefresh}
                      className="refresh-button"
                    >
                      Check Selection Status
                    </button>
                  )}
                  {!canManuallyRefresh && status === "waiting" && (
                    <p className="refresh-timer">
                      Next refresh available in{" "}
                      {Math.ceil(
                        (30000 - (Date.now() - lastPollingTime)) / 1000
                      )}{" "}
                      seconds
                    </p>
                  )}
                </div>
              </div>
            )}

            {status !== "processing" && (
              <button onClick={onClose} className="cancel-button">
                Cancel Selection
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSelector;
