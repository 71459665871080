import React, { useState } from "react";
import "../styles/Modal.css";

const WelcomeModal = ({ onClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("hideWelcomeModal", "true");
    }
    onClose();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2 className="step-title">You're All Set!</h2>
        <p className="step-description left-aligned">
          Here's what happens next:
        </p>
        <ul className="step-list left-aligned">
          <li className="step-item">
            <i className="fas fa-camera"></i> Choose Select Photos and choose
            any photos of a receipt
          </li>
          <li className="step-item">
            <i className="fas fa-envelope"></i> We'll email you with a link to
            your Expenses Spreadsheet.
          </li>
          <li className="step-item">
            <i className="fas fa-sync-alt"></i> For automatic syncing, turn on
            Auto Sync under Auto Syncing menu.
          </li>
        </ul>
        <div className="important-info">
          <i className="fas fa-exclamation-triangle"></i> Don't forget to add{" "}
          <strong>info@expensebot.ai</strong> to your safe sender list.
        </div>
        <div className="modal-footer">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={dontShowAgain}
              onChange={handleCheckboxChange}
            />
            <span className="checkbox-label">Don't show this again</span>
          </label>
          <button onClick={handleClose} className="close-button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
