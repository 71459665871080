import React, { useEffect, useState, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Home from "./components/Home";
import ProcessInfo from "./components/ProcessInfo";
import NoMatch from "./components/NoMatch";
import FAQPage from "./components/faq";
import TERMSPage from "./components/terms";
import PRIVACYPage from "./components/privacy";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [processInfo, setProcessInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Setup the Axios response interceptor inside useEffect to ensure it is only set once
  useEffect(() => {
    // Add Axios interceptor to capture new JWT from response headers
    const interceptor = axios.interceptors.response.use(
      (response) => {
        // Check if the `X-New-JWT` header is present in the response
        const newToken = response.headers["x-new-jwt"];
        if (newToken) {
          console.log("New token received from middleware:", newToken);

          // Update localStorage with the new token
          localStorage.setItem("token", newToken);
        }

        return response; // Continue with the original response
      },
      (error) => {
        // Handle the error accordingly
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor on component unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const fetchSessionInfo = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No token found in localStorage");
      setLoading(false);
      return;
    }

    try {
      console.log("Fetching session info with token:", token);
      const response = await axios.get("/api/session-info", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      setLoggedIn(true);
      setUser(data);
      setProcessInfo(data.processInfo);
    } catch (error) {
      console.error("Error fetching session info:", error);
      localStorage.removeItem("token");
      setLoggedIn(false);
      setUser(null);
      setProcessInfo(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const success = urlParams.get("success");
    const page = urlParams.get("page");
    const googleCode = urlParams.get("code");

    // Handle Google auth and other cases
    if (googleCode) {
      console.log("Making Google API call with code:", googleCode);
      axios
        .get(`/api/oauth2callback?code=${googleCode}`)
        .then((response) => {
          console.log("Received response:", response.data);
          const { token } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("auth_provider", "google");
          console.log("token set in app.js: ", token);
          fetchSessionInfo();
          const selectedMenu = localStorage.getItem("selectedMenu") || page;
          navigate("/process-info", { state: { returnPage: selectedMenu } });
          window.history.replaceState({}, document.title, "/");
        })
        .catch((error) => {
          console.error("Error handling Google OAuth callback:", error);
          if (error.response && error.response.status === 403) {
            alert(
              "It looks like some permissions were not granted. Please log in again and grant all required permissions."
            );
          }
          navigate("/");
        });
    } else if (success) {
      console.log("Inside app.js handling success=true");
      const token = localStorage.getItem("token");

      if (token) {
        console.log("in token app.js");
        axios
          .get(`/api/get-new-token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const { token: newToken } = response.data;
            console.log("new token: ", newToken);
            localStorage.setItem("token", newToken);
            fetchSessionInfo();
            navigate("/process-info");
          })
          .catch((error) => {
            console.error("Error fetching new token:", error);
            navigate("/");
          });
      } else {
        console.error("No token found in localStorage for fetching new token");
        navigate("/");
      }
    } else {
      fetchSessionInfo();
    }
  }, [fetchSessionInfo, location.pathname, location.search, navigate]);

  useEffect(() => {
    if (!loading) {
      // Avoid redirecting if the current path is '/success'
      if (
        location.pathname === "/success" ||
        location.pathname === "/faq" ||
        location.pathname === "/privacy" ||
        location.pathname === "/terms"
      ) {
        return;
      }

      if (!loggedIn) {
        console.log("Not logged in, redirecting to home");
        navigate("/");
      } else {
        console.log("Logged in, redirecting to process-info");
        navigate("/process-info", { replace: true });
      }
    }
  }, [loading, loggedIn, processInfo, location.pathname, navigate]);

  //  useEffect to handle Apple auth messages
  useEffect(() => {
    const handleAppleAuth = (event) => {
      if (event.data?.type === "APPLE_AUTH_SUCCESS") {
        console.log("Received Apple auth success:", event.data);
        const { token } = event.data;
        localStorage.setItem("token", token);
        localStorage.setItem("auth_provider", "apple");

        // Call fetchSessionInfo and wait for the user information to be set
        fetchSessionInfo().then(() => {
          setLoggedIn(true);
          const selectedMenu = localStorage.getItem("selectedMenu");
          navigate("/process-info", {
            state: { returnPage: selectedMenu },
            replace: true,
          });
          window.history.replaceState({}, document.title, "/");
        });
      }
    };

    window.addEventListener("message", handleAppleAuth);
    return () => window.removeEventListener("message", handleAppleAuth);
  }, [fetchSessionInfo, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<Home loggedIn={loggedIn} user={user} />} />
      {loggedIn && (
        <Route
          path="/process-info"
          element={
            <ProcessInfo
              user={user}
              setUser={setUser}
              processInfo={processInfo}
              setProcessInfo={setProcessInfo}
            />
          }
        />
      )}
      {/* Keep all your static routes */}
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/terms" element={<TERMSPage />} />
      <Route path="/privacy" element={<PRIVACYPage />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}
export default App;
