import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const FAQPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/"); // Navigate back to the home page
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">Frequently Asked Questions</h1>
        </div>
      </header>

      <div className="faq-item">
        <p className="faq-question">What is ExpenseBot?</p>
        <p className="faq-answer">
          ExpenseBot is an AI-powered service that helps manage your receipts by
          scanning photos in your Google Photos. Once you log in and connect
          your account, ExpenseBot will scan your photos daily, find receipts,
          and organize them into a Google Sheets spreadsheet. It simplifies
          expense management for both personal and business needs.
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">How do I use it?</p>
        <p className="faq-answer">
          After you login and accept permissisons, just take photos of your
          receipts like you normally would, and ExpenseBot will do the rest.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">How do I connect my photos?</p>
        <p className="faq-answer">
          Simply log in with your Google account and accept all the necessary
          permissions. ExpenseBot will then automatically connect to your Google
          Photos and start scanning for receipts daily.{" "}
          <strong> You need to use Google Photos. </strong>
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">
          Do I need Google Photos for this to work?
        </p>
        <p className="faq-answer">
          Yes, ExpenseBot works exclusively with Google Photos. Make sure your
          phone is set to automatically sync your photos to your Google Photos
          account, or the service will not function. Here's how to turn on
          automatic syncing for
          <a
            href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DiOS&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            iPhone
          </a>{" "}
          and
          <a
            href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DAndroid&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Android
          </a>
          .
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">
          What is the ExpenseBot email address to add to my spam filter?
        </p>
        <p className="faq-answer">
          Add info@expensebot.ai to your safe sender list or mark it as 'Not
          Spam'.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">
          How often does ExpenseBot scan my photos?
        </p>
        <p className="faq-answer">
          ExpenseBot scans your connected Google Photos daily for any new
          receipts. After each sync, you’ll receive an email summarizing the
          results.
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">
          What permissions does ExpenseBot require?
        </p>
        <p className="faq-answer">
          ExpenseBot needs the following Google permissions:
          <br />
          <strong>Google Photos - Read-Only :</strong> To scan your photos using
          AI to determine if its a receipt, then extract the necessary
          information.
          <br />
          <strong>Google Drive - Limited :</strong> Allows ExpenseBot to create
          a new folder to store your photos of receipts (on your own Drive) and
          create a new Google Sheets spreadsheet (on your own Drive) to show
          your expenses.
          <br />
        </p>
      </div>

      <div className="faq-item">
        <p className="faq-question">How does ExpenseBot manage my data?</p>
        <p className="faq-answer">
          We're proud of the modern, <strong> privacy-first </strong> approach
          we use for managing your data. Unlike most apps,{" "}
          <strong> ExpenseBot does not store your data</strong>. Instead, all
          the data generated by ExpenseBot, such as your photos of receipts and
          your Google Sheets, is securely stored on{" "}
          <strong> your own Google Drive, accessible only by you</strong>. Our
          AI scans your photos solely to identify receipts but never stores any
          of your photos or receipts. This ensures that you remain in full
          control of your information, with ExpenseBot simply facilitating tasks
          you would already do on your own.
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">
          Can I reset my account or delete my data?
        </p>
        <p className="faq-answer">
          Yes, under <strong> Settings </strong>, choose{" "}
          <strong> Reset Account </strong> to stop syncing, delete the the
          ExpenseBot folder on your drive and its photos of receipts, and the
          ExpenseBot spreadsheet we created for you. After you reset, you can
          start again from scratch or leave the service securely. Note that
          resetting your account will not affect your trial or subscription
          status.
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Do I need an app?</p>
        <p className="faq-answer">
          No, just sign in and accept permissions. You never need to come back
          to the site and you don't need an app. ExpenseBot will keep you
          informed by emailing you with relevant information and links.
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Why are some cells in YELLOW?</p>
        <p className="faq-answer">
          This means the ExpenseBot AI was uncertain about the value or it wants
          to provide additional information. Click the cell to see the note from
          the ExpenseBot AI.
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">
          Does ExpenseBot classify my receipts automatically?
        </p>
        <p className="faq-answer">
          Yes, ExpenseBot AI will classify your receipts into the correct
          category, and put them into a logical Expense Account group. You can
          give ExpenseBot instructions on the types of receipts to put into each
          Account or Category directly on the spreadsheet. See below under
          Helpful Tips!
        </p>
      </div>
      <div className="faq-item">
        <p className="faq-question">Can I request a feature or report a bug?</p>
        <p className="faq-answer">
          Yes, please email info@expensebot.ai with the subject "USER REQUEST".
        </p>
      </div>

      {/* Grouping helpful tips together */}

      <div className="faq-item">
        <p className="faq-question">Helpful Tips</p>
        <p className="faq-answer">
          <strong>Handwriting on Receipts:</strong> Write down the names of
          Expense Categories, Expense Accounts, or people's names on your
          receipts to help categorize your expenses.
          <br />
          <strong>Screenshots:</strong> Take a screen shot of a receipt from
          your email and ExpenseBot will find it and classify and extract it to
          your spreadsheet.
          <br />
          <strong> Editing Expense Categories & Accounts:</strong> You can
          directly edit or add new categories or accounts on your spreadsheet -
          look for the tabs at the bottom of the spreadsheet. Any changes will
          be reflected in the next sync.{" "}
          <strong>
            {" "}
            ExpenseBot auto-classifieds your receipts to those categories and
            accounts based on the instructions you write{" "}
          </strong>
          .
        </p>
      </div>
    </div>
  );
};

export default FAQPage;
