import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import "../styles/ProcessInfo.css";

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function CheckoutButton() {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found");
        setLoading(false);
        return;
      }

      const res = await axios.post(
        "/api/create-checkout-session",
        {}, // Send an empty object as the body
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = res.data;

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl; // Redirect to the URL provided in the response
      } else if (data.id) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.id,
        });

        if (error) {
          console.error("Stripe checkout error:", error);
        }
      } else {
        console.error("Failed to create checkout session");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }

    setLoading(false);
  };

  return (
    <button
      className="checkoutbutton logout-link"
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? "Loading..." : "Subscribe"}
    </button>
  );
}
