import React from "react";
import "../styles/Home.css";

const Home = () => {
  const handleGoogleLogin = () => {
    window.location.href = "/api/auth?skipConsent=true";
  };

  // Update handleAppleLogin in Home.js
  const handleAppleLogin = () => {
    // Determine if mobile
    const isMobile = window.innerWidth <= 768;

    // Set dimensions based on device
    const width = isMobile ? window.innerWidth : 600;
    const height = isMobile ? window.innerHeight : 700;

    // Center popup
    const left = isMobile ? 0 : window.screen.width / 2 - width / 2;
    const top = isMobile ? 0 : window.screen.height / 2 - height / 2;

    const features = isMobile
      ? "width=device-width,height=device-height,scrollbars=yes"
      : `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;

    const popup = window.open(
      "/api/auth/apple",
      "Sign in with Apple",
      features
    );

    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      alert("Please disable your popup blocker and try again.");
    }
  };

  // This condition can be changed as needed.
  const showAppleLogin = false;

  return (
    <div className="homecontainer">
      <header>
        <div className="header-content">
          <h1 className="logo">
            <img src="./eblogo_2.png" alt="ExpenseBot logo" />
          </h1>
          <h2 className="headline">
            ExpenseBot<span className="divider"> - </span>
            <span className="subheadline">Effortless Expense Management</span>
          </h2>
        </div>
      </header>

      <main className="content1">
        <div className="marketing-grid">
          <div className="marketing-item large-icon snap-receipt">
            <img src="./phone3.png" alt="Snap" className="icon" />
            <h3>Snap a Photo of Your Receipt</h3>
            <p>No app required. Just take a photo like you normally would.</p>
          </div>
          <div className="marketing-item large-icon autosync">
            <img src="./spreadsheet.png" alt="Auto-Sync" className="icon" />
            <h3>Auto-Added & Classified</h3>
            <p>
              ExpenseBot scans your photos for receipts, classifies and saves
              them to a Google Sheets spreadsheet we create for you.
            </p>
          </div>
          <div className="marketing-item large-icon ai-processing">
            <img src="./email.png" alt="AI Processing" className="icon" />
            <h3>Daily Notifications</h3>
            <p>ExpenseBot will email you everytime a new receipt is found.</p>
          </div>
        </div>
        <div className="login-section call-to-action">
          <div className="login-buttons">
            <button className="login-button google" onClick={handleGoogleLogin}>
              <img src="./g-logo.png" alt="Google" className="provider-icon" />
              Login with Google
            </button>
            {showAppleLogin && (
              <button className="login-button apple" onClick={handleAppleLogin}>
                <svg
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="provider-icon"
                >
                  <path
                    d="M14.152 12.258c-.597.946-1.218 1.89-2.195 1.89-.975 0-1.287-.577-2.404-.577-1.147 0-1.464.597-2.404.597-.95 0-1.673-1.05-2.27-1.996-1.24-1.79-2.18-5.066-.907-7.267.636-1.05 1.77-1.713 3.005-1.73 1.05 0 1.713.636 2.584.636.85 0 1.371-.636 2.603-.636 1.166.017 2.263.636 2.878 1.576-2.527 1.371-2.12 4.936.11 6.507zm-4.39-10.256c-.507.636-.1.1-1.322.1-.13-1.167.377-2.14.872-2.777.555-.675 1.527-1.166 2.32-1.166.11 1.187-.342 2.14-.837 2.777-.517.675-1.425 1.147-2.218 1.147z"
                    fill="#ffffff"
                    fillRule="nonzero"
                  />
                </svg>
                Sign in with Apple
              </button>
            )}
          </div>
        </div>
      </main>
      <footer className="footerhome">
        <p className="terms">
          By signing up, you agree to the ExpenseBot{" "}
          <a href="/privacy">Privacy Policy</a> and{" "}
          <a href="/terms">Terms of Service</a>. <br /> Read the{" "}
          <a href="/faq">ExpenseBot Faq</a> to see how it works.{" "}
        </p>
      </footer>
    </div>
  );
};

export default Home;
