import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import PhotoSelector from "./PhotoSelector";
import DateRangeSelector from "./DateRangeSelector";
import "../styles/ProcessInfo.css";
import { jwtDecode } from "jwt-decode";
import CheckoutButton from "./CheckoutButton";
import WelcomeModal from "./WelcomeModal";
import ReportModal from "./ReportModal";
import ShareToQuickBooks from "./ShareToQuickBooks";
import ICloudPhotoSelector from "./iCloudPhotoSelector";
const SHOW_ICLOUD_BUTTON = false;

const ProcessInfo = ({ user, setUser, processInfo, setProcessInfo }) => {
  const location = useLocation();
  const [photoSource, setPhotoSource] = useState(null);

  const [selectedMenu, setSelectedMenu] = useState("allExpenses");
  const [processing, setProcessing] = useState(
    !processInfo?.processingCompleted
  );
  const [lastSynced, setLastSynced] = useState(processInfo?.endDate || "");
  const [autoSyncEnabled, setAutoSyncEnabled] = useState(
    processInfo?.automaticSyncEnabled
  );
  const [nextSyncMessage, setNextSyncMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [noOutstandingExpenses, setNoOutstandingExpenses] = useState(false);
  const [showSeparatorAndContainer, setShowSeparatorAndContainer] =
    useState(true);
  const [loading, setLoading] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false); // Controls modal visibility
  const [formattedReportData, setFormattedReportData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [quickbooksConnectionStatus, setQuickbooksConnectionStatus] = useState(
    processInfo?.quickbooks?.connected || false
  );

  useEffect(() => {
    // Hide footer both when returning to expenseReports and when menu is set to expenseReports
    if (
      location.state?.returnPage === "expenseReports" ||
      selectedMenu === "expenseReports"
    ) {
      setSelectedMenu("expenseReports");
      setShowSeparatorAndContainer(false);
    }
  }, [location, selectedMenu]);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    localStorage.setItem("selectedMenu", menu); // Save to localStorage

    setShowSeparatorAndContainer(menu !== "expenseReports");
  };

  useEffect(() => {
    // Retrieve selectedMenu from localStorage on component mount
    const storedMenu = localStorage.getItem("selectedMenu");
    if (storedMenu) {
      setSelectedMenu(storedMenu);
    }
  }, []);

  const [isPhotoSelectorOpen, setIsPhotoSelectorOpen] = useState(false);

  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);

  const [cancelAt, setCancelAt] = useState(null);

  // New state variables for handling submission and displaying messages
  const [submitLoading, setSubmitLoading] = useState(false); // For loading spinner during submission
  const [submitResultMessage, setSubmitResultMessage] = useState(""); // For submission result messages

  // Collapsible states
  const [isAutoPilotOpen, setIsAutoPilotOpen] = useState(false);
  const [isManualSyncOpen, setIsManualSyncOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  /*
  const formatDate = (isoString) => {
    if (!isoString) return "N/A";
    try {
      const date = new Date(isoString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      }).format(date);
    } catch (error) {
      return "Invalid date";
    }
  }; */

  // Cycling through messages while loading

  // const [messageIndex, setMessageIndex] = useState(0);
  /*
  const messages = [
    "<strong> Feel free to close your browser </strong>, we'll email you when we're done.",
    `Scanning <strong> ${processInfo.totalPhotos} photos </strong> from Google Photos for receipts...`,
    "<strong> Write the expense group name on your receipt </strong> to help classify it.",
    `Current status: <strong> ${processInfo.status} </strong>`,
    "<strong> Edit expense groups </strong> on the <strong> Expense Groups tab </strong>.",
    "<strong> Your data is stored on your own Google Drive </strong>. We delete everything immediately after processing.",
    "<strong> Edit  expense categories </strong> on the <strong> Expense Categories tab </strong>.",
    "<strong> Write names of people on your receipt</strong>  and it will appear in <strong> Notes</strong>.",
    `ExpenseBot will check for receipts again on <strong> ${
      processInfo.nextSyncDate
        ? formatDate(
            new Date(processInfo.nextSyncDate).toLocaleString("en-US", {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Convert to local time zone
            })
          )
        : "N/A"
    } </strong>`,
    "<strong> Subscribe and cancel </strong> whenever you like. <strong> We make it easy for you </strong>.",
    `So far we found <strong> ${processInfo.receiptsCount} receipts.</strong>`,
  ];
*/
  /** 
  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [messages.length]);
   */
  /*  const formatMessage = (message) => {
    if (message.includes("strong")) {
      const parts = message.split(/(<strong>.*?<\/strong>)/);
      return parts.map((part, index) => {
        if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
          return (
            <strong key={index}>{part.replace(/<\/?strong>/g, "")}</strong>
          );
        }
        return part;
      });
    }
    return message;
  }; */

  const toggleSection = (section) => {
    if (section === "autoPilot") setIsAutoPilotOpen(!isAutoPilotOpen);
    if (section === "manualSync") setIsManualSyncOpen(!isManualSyncOpen);
    if (section === "settings") setIsSettingsOpen(!isSettingsOpen);
  };

  const triggerGlobalLoadingState = (updates = {}) => {
    setProcessing(true); // Disable all buttons and show the spinner
    setLoading((prevLoading) => ({
      ...prevLoading, // Preserve existing state
      photoSelector: true,
      dateRangeSelector: true,
      updateSync: true,
      ...updates, // Merge in additional updates
    }));
  };

  const updateSubscriptionStateFromToken = (decoded) => {
    console.log("Decoded token:", decoded); // Log decoded token to ensure it's correct

    setIsSubscribed(decoded.isSubscribed);
    setIsTrial(decoded.isTrial);
    setSubscriptionEndDate(decoded.subscriptionEndDate);
    setCancelAt(decoded.cancelAt);

    if (decoded.isTrial && decoded.trialEndDate) {
      const endDate = new Date(decoded.trialEndDate);
      const currentDate = new Date();

      // Reset the hours of both dates to avoid partial day discrepancies
      endDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      const diffTime = endDate.getTime() - currentDate.getTime();
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

      // Log trialEndDate and the calculated remaining trial days
      console.log(`Trial End Date: ${endDate}`);
      console.log(`Current Date: ${currentDate}`);
      console.log(`Days Remaining in Trial: ${diffDays}`);

      setTrialDaysRemaining(diffDays);
    }
  };

  const resetGlobalLoadingState = () => {
    setProcessing(false); // This will enable all buttons and hide the spinner
    setLoading((prevLoading) => ({
      ...prevLoading, // Preserve existing properties like photoCount
      photoSelector: false,
      dateRangeSelector: false,
      updateSync: false,
    }));
  };

  const handlePhotoSelector = (source) => {
    if (source === "google") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("google");
    } else if (source === "icloud") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("icloud");
    }
  };

  const handleDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(true);
  };

  const handlePhotosSelected = async (mediaItems) => {
    console.log("Media items length:", mediaItems.length);
    triggerGlobalLoadingState({ photoCount: mediaItems.length });
    // Clear any previous error when user retries
    setProcessInfo((prevInfo) => ({
      ...prevInfo,
      error: null, // Clear the error
    }));
    try {
      // Log the incoming data structure
      console.log("Received media items from PhotoSelector:", mediaItems);

      // Don't need to transform the data since PhotoSelector already provides the correct format
      const response = await axios.post(
        `/api/listPhotos`,
        {
          userSelectedPhotos: mediaItems,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Payload sent to server:", {
        userSelectedPhotos: mediaItems,
      });

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,
          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          lastSubmittedDate: data.processInfo.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
        alert("Error updating sync. Please try again.");
      }
    } catch (error) {
      console.error("Error processing photos:", error);
      alert("Error processing selected photos. Please try again.");
    } finally {
      resetGlobalLoadingState();
    }
  };

  const handlePhotoSelectorError = (errorMessage) => {
    alert(errorMessage);
    resetGlobalLoadingState();
  };

  const handleDateRangeSelected = async (startDate, endDate) => {
    setIsDateRangeSelectorOpen(false);
    triggerGlobalLoadingState();

    const token = localStorage.getItem("token");
    if (!token) {
      resetGlobalLoadingState(); // Reset if there's no token
      return;
    }

    // Manually set processingCompleted to false to trigger the processing message
    setProcessInfo((prevInfo) => ({
      ...prevInfo,
      processingCompleted: false,
    }));

    try {
      const response = await axios.get(`/api/listPhotos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate,
          endDate,
        },
      });

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,

          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          lastSubmittedDate: data.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
      }
    } catch (error) {
      console.error(
        "Error updating sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // Do not reset the loading state here. Let the effect hook handle it.
    }
  };

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const initializeQuickBooksConnection = async () => {
    try {
      const response = await fetch("/api/quickbooks/status", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to check status");
      }
      const status = await response.json();
      setQuickbooksConnectionStatus(status?.connected || false);
    } catch (err) {
      console.error("Error checking QB status:", err);
      setQuickbooksConnectionStatus(false);
    }
  };

  useEffect(() => {
    // Check if we have a returnPage in the state
    if (location.state?.returnPage === "expenseReports") {
      setSelectedMenu("expenseReports");
      setShowSeparatorAndContainer(false); // Hide footer when returning to expenseReports
    }
  }, [location]);

  useEffect(() => {
    initializeQuickBooksConnection();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      updateSubscriptionStateFromToken(decoded);
    }

    // Listen for storage events to handle token updates from other tabs
    window.addEventListener("storage", () => {
      const updatedToken = localStorage.getItem("token");
      if (updatedToken) {
        const decoded = jwtDecode(updatedToken);
        updateSubscriptionStateFromToken(decoded);
      }
    });

    return () => {
      window.removeEventListener("storage", () => {});
    };
  }, []);

  const handlePreviewSubmission = async () => {
    setSubmitResultMessage("");
    setShowPreview(false); // Hide the preview initially
    setDataLoading(true); // Show loading graphic

    try {
      const response = await axios.get("/api/getReportData", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const { reportData } = response.data;

      // Set the formatted reportData to state for rendering
      setFormattedReportData(reportData);

      // Check if there is no data to display
      console.log("reportData.length ", reportData.length);
      if (reportData.length > 0) {
        setIsModalOpen(true); // Open modal only if data is present
        setNoOutstandingExpenses(false);
      } else {
        setNoOutstandingExpenses(true); // If no data, show a message
      }
    } catch (error) {
      console.error("Error processing preview submission:", error.message);
    } finally {
      setDataLoading(false); // Hide loading graphic
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    setSubmitResultMessage("");

    try {
      const response = await axios.post(
        `/api/getReportData`,
        { action: "submit" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      const { message, submissionDate, title, link, reportId } = response.data;

      if (response.status === 200) {
        // Create new report with the proper structure
        const batchNumber = `batch-${Date.now()}`;
        const newReport = {
          batchNumber,
          link,
          reportId,
          submissionDate,
          title: title || `Expense Report - ${batchNumber}`,
          quickbooks: {
            error: null,
            status: "not_submitted",
            submittedAt: null,
            transactionId: null,
          },
        };

        // Update processInfo by adding the new report at the beginning of the array
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          lastSubmittedDate: submissionDate,
          reports: [newReport, ...(prevInfo.reports || [])],
        }));

        // Set the result message
        setSubmitResultMessage(message || "Report submitted successfully!");
      } else {
        setSubmitResultMessage(
          message || "An error occurred while submitting the report."
        );
      }
    } catch (error) {
      const serverMessage =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      setSubmitResultMessage(serverMessage);
    } finally {
      setFormattedReportData([]);
      setShowPreview(false);
      setSubmitLoading(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const hideWelcomeModal = localStorage.getItem("hideWelcomeModal");
    if (!hideWelcomeModal) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    // When processingCompleted becomes true, reset loading states for the buttons
    if (processInfo.processingCompleted) {
      resetGlobalLoadingState();
    }
  }, [processInfo.processingCompleted]);

  // Initialize loading.updateSync to false when the component mounts
  useEffect(() => {
    setLoading((prev) => ({ ...prev, updateSync: false }));
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const confirmCancelSubscription = async () => {
    setLoading((prev) => ({ ...prev, cancelSubscription: true }));

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        resetGlobalLoadingState();
        return;
      }

      console.log("Sending cancel subscription request...");

      // Send the request to cancel the subscription
      const response = await axios.post(
        "/api/cancel-subscription",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Cancel subscription response:", response.data);

      const { newToken } = response.data;

      localStorage.setItem("token", newToken); // Save the new token
      const decoded = jwtDecode(newToken);

      // Update the state with the new subscription info
      updateSubscriptionStateFromToken(decoded);
      setUser(decoded); // Update the user state
    } catch (error) {
      console.error("Error canceling subscription:", error.message);
    } finally {
      setLoading((prev) => ({ ...prev, cancelSubscription: false }));
    }
  };

  useEffect(() => {
    if (processInfo?.automaticSyncEnabled) {
      setAutoSyncEnabled(processInfo.automaticSyncEnabled);
    }
  }, [processInfo?.automaticSyncEnabled]);

  // When the component mounts, if processing hasn't been completed yet, trigger the global loading state
  useEffect(() => {
    if (!processInfo?.processingCompleted) {
      triggerGlobalLoadingState();
    }
  }, [processInfo]);

  useEffect(() => {
    console.log("Current processInfo:", processInfo); // Log processInfo
    console.log(
      "Current processingCompleted status:",
      processInfo?.processingCompleted
    );

    if (processInfo?.processingCompleted) {
      console.log("Processing is completed, no polling required.");
      resetGlobalLoadingState();
    } else {
      console.log("Processing not completed, triggering global loading state.");
      triggerGlobalLoadingState();
    }
  }, [processInfo]);

  const updateBackendStatus = async () => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        "/api/update-session",
        {
          processingCompleted: true,
          status:
            "Processing may take longer than expected. Please check back in a few minutes.",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Backend status updated to completed");
    } catch (error) {
      console.error("Failed to update backend status:", error);
    }
  };

  useEffect(() => {
    let hasPolledOnceAfterCompletion = false;
    let interval = null;
    let pollCount = 0; // Add poll counter

    const pollUpdates = async () => {
      const token = localStorage.getItem("token");
      if (!token || processInfo?.processingCompleted) {
        console.log(
          "Skipping poll as processingCompleted is true or no token."
        );
        return;
      }

      pollCount++; // Increment poll counter

      // Add max polls check
      if (pollCount >= 10) {
        // 3 minutes (18 x 10 seconds)
        console.log("Max polls reached, updating backend and stopping");
        clearInterval(interval);
        await updateBackendStatus();
        resetGlobalLoadingState();
        setProcessInfo((prev) => ({
          ...prev,
          status:
            "Processing may take longer than expected. Please check back in a few minutes.",
          processingCompleted: true,
        }));
        return;
      }

      try {
        const response = await axios.get(`/api/getProcessState`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        if (data.success) {
          console.log("Received poll update:", data);

          setProcessInfo((prevInfo) => ({
            ...prevInfo,
            receiptsCount: data.processInfo.receiptsCount,
            duplicatesDeleted: data.processInfo.duplicatesDeleted,
            spreadsheetId: data.processInfo.spreadsheetId,

            lastProcessedDate: data.processInfo.lastProcessedDate,
            processingCompleted: data.processInfo.processingCompleted,
            endDate: data.processInfo.endDate,
            totalPhotos: data.processInfo.totalPhotos,
            status: data.processInfo.status,
            nextSyncDate: data.processInfo.nextSyncDate,
            totalReceiptCount: data.processInfo.totalReceiptCount,
            lastSubmittedDate: data.processInfo.lastSubmittedDate,
          }));

          setLastSynced(data.processInfo.endDate);

          if (data.processInfo.processingCompleted) {
            console.log(
              "Processing completed, stopping poll after one more time."
            );
            if (hasPolledOnceAfterCompletion) {
              clearInterval(interval);
            } else {
              hasPolledOnceAfterCompletion = true;
            }
          }
        } else {
          console.error("Error in poll update:", data.message);
        }
      } catch (error) {
        console.error("Error fetching process state:", error);
      }
    };

    if (!processInfo?.processingCompleted) {
      console.log("Setting up polling interval...");
      interval = setInterval(pollUpdates, 30000); // Poll every 30 seconds
    } else {
      console.log("Processing already completed, no polling set.");
    }

    return () => {
      console.log("Clearing interval...");
      clearInterval(interval);
    };
  }, [processInfo, setProcessInfo]);

  const handleUpdateSync = async () => {
    triggerGlobalLoadingState();

    const token = localStorage.getItem("token");
    if (!token) {
      resetGlobalLoadingState();
      return;
    }

    if (!processInfo || !processInfo.endDate) {
      resetGlobalLoadingState();
      return;
    }

    try {
      // Manually set processingCompleted to false to trigger the processing message
      setProcessInfo((prevInfo) => ({
        ...prevInfo,
        processingCompleted: false,
      }));

      const response = await axios.get(
        `/api/listPhotos?startDate=${processInfo.endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,

          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          lastSubmittedDate: data.processInfo.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
      }
    } catch (error) {
      console.error(
        "Error updating sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // Do not reset the loading state here. Let the effect hook handle it.
    }
  };

  const handleToggleAutoSync = async () => {
    setLoading({ ...loading, autoSync: true });
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "/api/toggle-auto-sync",
        { autoSyncEnabled: !autoSyncEnabled },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setAutoSyncEnabled(!autoSyncEnabled);
      } else {
        console.error("Error toggling auto-sync:", response.data.message);
      }
    } catch (error) {
      console.error(
        "Error toggling auto-sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading({ ...loading, autoSync: false });
    }
  };

  const handleReset = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account, your Google Drive with your receipts, and your spreadsheet with your expenses? This action cannot be undone."
      )
    ) {
      setLoading({ ...loading, reset: true });
      try {
        const token = localStorage.getItem("token");
        await axios.post(
          "/api/reset-account",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.removeItem("token");
        window.location.href = "/";
      } catch (error) {
        console.error("Error resetting account:", error.message);
      } finally {
        setLoading({ ...loading, reset: false });
      }
    }
  };

  const handleLogout = () => {
    setLoading({ ...loading, logout: true });
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  const openSpreadsheet = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gId}`,
      "_blank"
    );
  };

  const openSpreadsheetBilling = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidBilling}`,
      "_blank"
    );
  };

  const openSpreadsheetExpenses = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit`,
      "_blank"
    );
  };

  const renderAllExpenses = () => (
    <div className="info-container">
      <div
        className="photo-selection-container"
        style={{ marginBottom: "20px", textAlign: "left" }}
      >
        {/* Always show buttons */}
        <div className="photo-source-buttons">
          <button
            className="primary-button google-button"
            onClick={() => handlePhotoSelector("google")}
            disabled={
              processing ||
              loading.photoSelector ||
              !processInfo.processingCompleted
            }
          >
            <span className="button-icon google-icon"></span> Get Photos from
            Google Photos
          </button>

          {SHOW_ICLOUD_BUTTON && (
            <button
              className="secondary-button icloud-button"
              onClick={() => handlePhotoSelector("icloud")}
              disabled={processing || loading.photoSelector}
            >
              <span className="button-icon icloud-icon"></span> Get Photos from
              iCloud
            </button>
          )}
        </div>

        {/* Loading message underneath */}
        {loading.photoSelector && (
          <div className="loading-row">
            <span className="loading-text">
              <i className="fas fa-info-circle info-icon"></i>
              {`Processing ${
                loading.photoCount > 0 ? loading.photoCount : "your"
              } photos, we will email you from info@expensebot.ai when done.`}
            </span>
          </div>
        )}

        {/* Error message if present */}
        {processInfo.error && (
          <div
            className="error-message"
            style={{
              color: "#dc3545",
              marginTop: "10px",
              fontSize: "0.9rem",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <i className="fas fa-exclamation-circle"></i>
            {processInfo.error}
          </div>
        )}
      </div>
      <div
        style={{
          marginBottom: "15px",
          borderBottom: "1px solid #d0d7de", // Made border thinner and lighter
          paddingBottom: "8px",
        }}
      >
        <h3
          style={{
            color: "#0033a0",
            fontSize: "1.1rem", // Reduced from 1.2rem to 1.1rem
            fontWeight: "600",
            margin: 0,
          }}
        >
          Expenses Summary
        </h3>
      </div>
      <div className="summary-section">
        <div className="summary-item">
          <p className="label">
            <strong>Total Receipts</strong>
          </p>
          <p className="value">{processInfo.totalReceiptCount ?? 0}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheetExpenses}>
              View
            </button>
          )}
        </div>
        <div className="summary-item">
          <p className="label">
            <strong>Last Successful Sync</strong>
          </p>
          <p className="value">
            {processInfo.lastProcessedDate
              ? new Date(processInfo.lastProcessedDate).toLocaleDateString()
              : ""}
          </p>
        </div>

        <div className="summary-item">
          <p className="label">
            <strong>Recent Receipts Found</strong>
          </p>
          <p className="value">{processInfo.receiptsCount ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">
            <strong>Recent Duplicates Deleted</strong>
          </p>
          <p className="value">{processInfo.duplicatesDeleted ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">
            <strong>Smart Accounts</strong>
          </p>
          <p className="value">{processInfo.expenseCategoryCount}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheet}>
              Edit
            </button>
          )}
        </div>

        <div className="summary-item">
          <p className="label">
            <strong>Smart Expense Categories</strong>
          </p>
          <p className="value">{processInfo.reportCategoryCount}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheetBilling}>
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );

  // Function to render the always-visible section with the date range selector
  const renderAlwaysVisibleSection = () => (
    <div className="expense-reports-container">
      <h3 className="expense-report-title">Submit for Approval</h3>

      {/* Flex container for date range and button */}
      <div className="date-range-and-button">
        {/* Date Range Selectors */}
        <div className="date-range-selectors">
          {/* <div className="date-range-inline">
    <label htmlFor="start-date" className="date-label"></label>
    <input
      type="date"
      id="start-date"
      name="start-date"
      defaultValue="2024-09-20"
      className="date-input"
    />
    <span className="date-separator">to</span>
    <label htmlFor="end-date" className="date-label"></label>
    <input
      type="date"
      id="end-date"
      name="end-date"
      defaultValue={new Date().toISOString().split("T")[0]}
      className="date-input"
    />
  </div> */}

          {processInfo?.lastSubmittedDate ? (
            <div className="last-submitted">
              Last Submitted:{" "}
              {new Date(processInfo.lastSubmittedDate).toLocaleDateString()}
            </div>
          ) : (
            <div className="last-submitted">No submissions yet.</div>
          )}
        </div>

        {/* Preview Submission Button */}
        <button
          className="primary-button preview-button button-width"
          onClick={handlePreviewSubmission}
        >
          Preview Submission
        </button>
      </div>
      {/* Display the submission result message here */}
      {/* Display the submission result message here */}
      {submitResultMessage && (
        <div
          className={`message-box ${
            submitResultMessage.toLowerCase().includes("error")
              ? "error"
              : "success"
          }`}
          dangerouslySetInnerHTML={{ __html: submitResultMessage }}
        />
      )}
    </div>
  );
  const DisplayReport = ({ reportData, handleSubmit }) => (
    <div className="report-container">
      {reportData.map(({ currency, accounts, totalCurrency }) => (
        <div key={currency} className="currency-section">
          <h3>{currency}</h3>
          {accounts.map(({ account, total }) => (
            <div className="total-item" key={account}>
              <p className="total-label">{account}</p>
              <p className="total-value">${total.toFixed(2)}</p>
            </div>
          ))}
          <div className="total-item">
            <p className="total-label">
              <strong>Total {currency}:</strong>
            </p>
            <p className="total-value">${totalCurrency.toFixed(2)}</p>
          </div>
        </div>
      ))}

      {/* Submit Section */}
      <div className="submit-review-section">
        {/* Display loading spinner or submit button based on the loading state */}
        {submitLoading ? (
          <div className="loading-container">
            <TailSpin color="#0033a0" height={5} width={5} />
            <p>Submitting report, please wait...</p>
          </div>
        ) : (
          <button
            className="submit-review-button primary-button button-width"
            onClick={handleSubmit}
            disabled={submitLoading} // Disable the button while submitting
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );

  const renderPreviouslySubmittedReports = () => (
    <div className="expense-reports-container">
      <h3 className="expense-report-title">Submitted Reports</h3>
      {processInfo?.reports && processInfo.reports.length > 0 ? (
        <div className="report-grid">
          {processInfo.reports.slice().map((report, index) => (
            <div key={index} className="report-row">
              <div className="report-date">
                {new Date(report.submissionDate).toLocaleDateString("en-CA")}
              </div>
              <div className="report-link">
                <a href={report.link} target="_blank" rel="noopener noreferrer">
                  {report.title}
                </a>

                <ShareToQuickBooks
                  reportId={report.reportId}
                  quickbooks={report.quickbooks}
                  isConnected={quickbooksConnectionStatus}
                  onStatusUpdate={(newStatus) => {
                    setProcessInfo((prev) => ({
                      ...prev,
                      reports: prev.reports.map((r) =>
                        r.reportId === report.reportId
                          ? {
                              ...r,
                              quickbooks: {
                                ...r.quickbooks,
                                ...newStatus,
                              },
                            }
                          : r
                      ),
                    }));
                  }}
                  onConnectionStatusChange={(status) => {
                    setQuickbooksConnectionStatus(status);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No previously submitted reports available.</p>
      )}
    </div>
  );

  const formatRelativeTime = (date) => {
    const now = new Date();
    const diff = date - now;
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (diffHours < 0 || diffMinutes < 0) {
      return "<check auto-pilot settings>";
    }

    return `${diffHours} hours and ${diffMinutes} minutes`;
  };

  const formatUserFriendlyDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      weekday: "long",
    }).format(date);
  };

  useEffect(() => {
    const message = autoSyncEnabled ? (
      <div className="sync-info-secondary">
        <p>
          <i className="fas fa-sync-alt"></i> <strong>Next sync: </strong>
          {processInfo.nextSyncDate
            ? formatRelativeTime(new Date(processInfo.nextSyncDate))
            : "(calculating next sync...)"}
        </p>
        <p>
          <i className="fas fa-clock"></i> Last sync:
          <strong>
            {lastSynced
              ? formatUserFriendlyDate(new Date(lastSynced))
              : "(no sync data)"}
          </strong>
        </p>
      </div>
    ) : null;

    setNextSyncMessage(message);
  }, [autoSyncEnabled, processInfo.nextSyncDate, lastSynced]);

  useEffect(() => {
    if (processInfo?.endDate) {
      setLastSynced(processInfo.endDate);
    }
  }, [processInfo?.endDate]);

  return (
    <>
      <div className="container">
        {showModal && <WelcomeModal onClose={handleCloseModal} />}

        {user && (
          <div className="profile">
            <img
              id="user-picture"
              src={user.picture || `./eblogo_192.png`}
              alt="User profile"
            />
            <div className="profile-info">
              <button className="logout-link" onClick={handleLogout}>
                Logout
              </button>
              <div className="subscription-status">
                {cancelAt ? (
                  <>
                    <CheckoutButton
                      setUser={setUser}
                      updateUI={updateSubscriptionStateFromToken}
                    />
                    <p>
                      Your subscription is set to cancel on{" "}
                      {new Date(cancelAt).toLocaleDateString()}.
                    </p>
                  </>
                ) : isSubscribed ? (
                  <>
                    <button
                      className="cancel-subscription-link"
                      onClick={confirmCancelSubscription}
                      disabled={loading.cancelSubscription}
                    >
                      {loading.cancelSubscription ? (
                        <TailSpin color="#0033a0" height={24} width={24} />
                      ) : (
                        "Cancel Subscription"
                      )}
                    </button>

                    <p>
                      You are subscribed until{" "}
                      {new Date(subscriptionEndDate).toLocaleDateString()}.
                    </p>
                  </>
                ) : isTrial ? (
                  <>
                    <CheckoutButton
                      setUser={setUser}
                      updateUI={updateSubscriptionStateFromToken}
                    />
                    <p className="checkout-text">
                      Free trial ends in {trialDaysRemaining} days
                    </p>
                  </>
                ) : (
                  <>
                    <CheckoutButton
                      setUser={setUser}
                      updateUI={updateSubscriptionStateFromToken}
                    />
                    <p className="checkout-text">
                      Your subscription has ended. Please subscribe to continue
                      using the service.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="submenu">
          <button
            className={`menu-link ${
              selectedMenu === "allExpenses" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("allExpenses")}
          >
            All Expenses
          </button>
          <button
            className={`menu-link ${
              selectedMenu === "expenseReports" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("expenseReports")}
          >
            Summary Expense Report
          </button>
        </div>
        <div className="menu-content-container">
          {selectedMenu === "allExpenses" ? (
            renderAllExpenses()
          ) : (
            <div>
              {/* Render the always-visible section for date range and submission */}
              {renderAlwaysVisibleSection()}
              {/* Show the loading spinner only when the report is being generated */}
              {dataLoading ? (
                <div className="loading-container">
                  <TailSpin color="#0033a0" height={20} width={20} />
                  <p>Generating report, please wait...</p>
                </div>
              ) : showPreview && formattedReportData.length > 0 ? (
                <DisplayReport
                  reportData={formattedReportData}
                  handleSubmit={handleSubmit}
                />
              ) : noOutstandingExpenses ? (
                <p style={{ marginLeft: "20px", color: "#0033a0" }}>
                  No outstanding expenses to submit.
                </p>
              ) : (
                <> </> // Show nothing if noPreview or no data
              )}
              {/* Render Previously Submitted Reports Section - Always visible */}
              {renderPreviouslySubmittedReports()}
            </div>
          )}
        </div>

        {showSeparatorAndContainer && <div className="separator"></div>}
        {showSeparatorAndContainer && (
          <div className="container">
            {false && (
              <div className="tuning-section">
                <h2 onClick={() => toggleSection("manualSync")}>
                  Manual Syncing {isManualSyncOpen ? "▲" : "▼"}
                </h2>
                {isManualSyncOpen && (
                  <div className="collapsible-content">
                    <div className="grid-container">
                      {loading.photoSelector || loading.dateRangeSelector ? (
                        <div className="loading-row">
                          <span className="loading-text">
                            <i className="fas fa-info-circle info-icon"></i>
                            {`Processing ${
                              loading.photoCount > 0
                                ? loading.photoCount
                                : "your"
                            } photos, we will email you from info@expensebot.ai when done.`}
                          </span>
                        </div>
                      ) : (
                        <>
                          <div className="grid-item">
                            <div>
                              <span>Photos</span>
                            </div>
                            <button
                              className="link-button"
                              onClick={() => setIsPhotoSelectorOpen(true)}
                              disabled={
                                processing ||
                                loading.photoSelector ||
                                !processInfo.processingCompleted
                              }
                            >
                              Select Photos
                            </button>

                            {isPhotoSelectorOpen && (
                              <div className="modal-backdrop">
                                <PhotoSelector
                                  onClose={() => setIsPhotoSelectorOpen(false)}
                                  onPhotosSelected={handlePhotosSelected}
                                  onError={handlePhotoSelectorError}
                                />
                              </div>
                            )}
                          </div>

                          <div className="grid-item">
                            <span>Dates</span>
                            <button
                              className="link-button"
                              onClick={handleDateRangeSelector}
                              disabled={
                                processing ||
                                loading.dateRangeSelector ||
                                !processInfo.processingCompleted
                              }
                            >
                              Select Date Range
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {false && (
              <>
                {nextSyncMessage}
                <div className="tuning-section">
                  <h2 onClick={() => toggleSection("autoPilot")}>
                    Auto Syncing {isAutoPilotOpen ? "▲" : "▼"}
                  </h2>
                  {isAutoPilotOpen && (
                    <div className="collapsible-content">
                      <div className="grid-container">
                        <div className="grid-item">
                          <span>
                            Autosync: {autoSyncEnabled ? "ON" : "OFF"}
                          </span>
                          <button
                            className="link-button"
                            onClick={handleToggleAutoSync}
                            disabled={loading.autoSync}
                          >
                            {loading.autoSync ? (
                              <TailSpin
                                color="#0033a0"
                                height={24}
                                width={24}
                              />
                            ) : autoSyncEnabled ? (
                              "Turn off"
                            ) : (
                              "Turn on"
                            )}
                          </button>
                        </div>

                        <div className="grid-item">
                          <span>Force Sync</span>
                          <button
                            className="link-button"
                            onClick={handleUpdateSync}
                            disabled={
                              processing ||
                              loading.updateSync ||
                              !processInfo.processingCompleted
                            }
                          >
                            {loading.updateSync ? (
                              <TailSpin
                                color="#0033a0"
                                height={24}
                                width={24}
                              />
                            ) : (
                              "Force Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="tuning-section">
              <h2 onClick={() => toggleSection("settings")}>
                Settings {isSettingsOpen ? "▲" : "▼"}
              </h2>
              {isSettingsOpen && (
                <div className="collapsible-content">
                  <div className="grid-container">
                    {/* Reset Button */}
                    <div className="grid-item">
                      <span>Reset Account</span>
                      <button
                        className="link-button"
                        onClick={handleReset}
                        disabled={loading.reset}
                      >
                        {loading.reset ? (
                          <TailSpin color="#0033a0" height={24} width={24} />
                        ) : (
                          "Reset"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isPhotoSelectorOpen &&
              (photoSource === "google" ? (
                <PhotoSelector
                  onClose={() => {
                    setIsPhotoSelectorOpen(false);
                    setPhotoSource(null);
                  }}
                  onPhotosSelected={handlePhotosSelected}
                  onError={(error) => {
                    alert(error);
                    setIsPhotoSelectorOpen(false);
                    setPhotoSource(null);
                  }}
                />
              ) : (
                <ICloudPhotoSelector
                  onClose={() => {
                    setIsPhotoSelectorOpen(false);
                    setPhotoSource(null);
                  }}
                  onPhotosSelected={handlePhotosSelected}
                  onError={(error) => {
                    alert(error);
                    setIsPhotoSelectorOpen(false);
                    setPhotoSource(null);
                  }}
                />
              ))}
            {isDateRangeSelectorOpen && (
              <div className="modal-backdrop">
                <DateRangeSelector
                  onClose={() => setIsDateRangeSelectorOpen(false)}
                  onDateRangeSelected={handleDateRangeSelected}
                />
              </div>
            )}

            <footer className="footerpi">
              <div className="footer-contentpi">
                <img
                  src="./eblogo_2.png"
                  alt="ExpenseBot logo"
                  className="footer-logopi"
                />
                <p className="footer-textpi">Powered by ExpenseBot</p>

                <a href="/faq" className="faq-link">
                  FAQ - Frequently Asked Questions
                </a>
              </div>
            </footer>
          </div>
        )}

        <ReportModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          reportData={formattedReportData}
          handleSubmit={handleSubmit}
          submitLoading={submitLoading}
          submitResultMessage={submitResultMessage}
        />
      </div>
    </>
  );
};

export default ProcessInfo;
