import React from "react";
import "../styles/ProcessInfo.css"; // Reuse the existing CSS styles
import { TailSpin } from "react-loader-spinner";

// Modal component
const ReportModal = ({
  isOpen,
  onClose,
  reportData,
  handleSubmit,
  submitLoading,
  submitResultMessage,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-header">
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
          <h2>Preview Report</h2>
        </div>
        <div className="report-container">
          {/* Reusing existing DisplayReport structure */}
          {reportData.length > 0 ? (
            <div className="currency-section">
              {reportData.map(({ currency, accounts, totalCurrency }) => (
                <div key={currency}>
                  <h3>{currency}</h3>
                  {accounts.map(({ account, total }) => (
                    <div className="total-item" key={account}>
                      <p className="total-label">{account}</p>
                      <p className="total-value">${total.toFixed(2)}</p>
                    </div>
                  ))}
                  <div className="total-item">
                    <p className="total-label">
                      <strong>Total {currency}:</strong>
                    </p>
                    <p className="total-value">${totalCurrency.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No report data to display</p>
          )}

          {/* Submit Section */}
          <div className="submit-review-section">
            {submitLoading ? (
              <div className="loading-container">
                <TailSpin color="#0033a0" height={20} width={20} />
                <p>Submitting report, please wait...</p>
              </div>
            ) : (
              <button
                className="submit-review-button primary-button button-width"
                onClick={handleSubmit}
                disabled={submitLoading}
              >
                Submit
              </button>
            )}
            {submitResultMessage && (
              <div
                className={`message-box ${
                  submitResultMessage.includes("error") ? "error" : "success"
                }`}
              >
                {submitResultMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
